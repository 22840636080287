header {
  background-color: #fff;
}
.topBar {
  padding: 0;
  border-bottom: solid 1px var(--lightGray);
}

.topBar h2{
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
}

.topBar ul.contacts {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}
.topBar ul.contacts li,
footer ul.list.contacts li {
  display: flex;
  gap: 6px;
  border-left: solid 1px var(--lightGray);
  line-height: 1.2;
  font-size: 13px;
  padding: 20px 15px;
}
.topBar ul.contacts li:last-child {
  border-right: solid 1px var(--lightGray);
}
.topBar ul.contacts li b,
footer ul.list.contacts li b {
  display: block;
  font-size: 18px;
  color: var(--text400);
}
.topBar ul.contacts li svg {
  font-size: 28px;
}
.logo h2 {
  color: var(--secondary400);
}
.logo p {
  font-size: 16px;
  font-weight: 500;
}
.bottomBar {
  background-color: var(--primary400);
}
.bottomBar a {
  color: #fff;
  font-size: 15px;
  padding: 2px 5px;
  border-radius: 3px;
  text-align: center;
}
.mobile_applynowbtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bottomBar .applynow__btn,
.mobile_applynowbtn > a {
  background-color: var(--secondary400);
  color: #fff;
  font-weight: 700;
  padding: 7px 10px;
  border-radius: 4px;
  transition: 0.3s ease;
  line-height: 1;
}

footer {
  background-color: var(--darkGray);
  padding: 100px 0 50px;
}

footer h4 {
  font-size: 17px;
  color: #e7e7e7;
  font-weight: 500;
  margin: 0 0 40px;
}
footer ul.list {
  list-style: none;
  padding: 0;
  margin: 0;
}
footer ul.list li {
  line-height: 1.15;
  margin: 15px 0;
}
footer ul.list li a {
  transition: 0.3s ease;
  color: var(--text400);
}
footer ul.list li a:hover {
  color: #fff;
}

footer .disclaimer {
  border: 1px dashed var(--text400);
  padding: 15px;
  margin-top: 40px;
  font-size: 14px;
}
footer ul.list.contacts li {
  border: none;
  padding: 0;
  gap: 20px;
  font-size: 16px;
  color: #ffffff54;
}
footer ul.list.contacts li b {
  font-size: 20px;
  line-height: 1.5;
}
footer ul.list.contacts li svg {
  font-size: 35px;
}

footer .select_country {
  margin: 0 0 40px;
}

footer .card_wrap ul.cards {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
/* //this new footer  */
.footerNew {
  background-color: #f5f5f5;
  padding-top: 20px;
}
.footerNew ul.Links_list {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.footerNew ul.Links_list li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.footerNew ul.list.contacts li b {
  font-size: 20px;
  line-height: 1.5;
}
.footerNew ul.list.contacts li svg {
  font-size: 35px;
}
.footerNew .select_country {
  margin: 0 0 40px;
}
.footerNew .card_wrap ul.cards {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.footerNew .card_wrap ul.cards li {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}
.footerNew .card_wrap ul.cards li img {
  height: 30px;
}

footer .card_wrap ul.cards li {
  padding: 5px;
  background-color: #fff;
  border-radius: 5px;
}
footer .card_wrap ul.cards li img {
  height: 30px;
}

footer ul.socialLinks {
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
}
footer ul.socialLinks li svg {
  font-size: 25px;
  transition: 0.3s ease;
  color: #fff;
}
footer ul.socialLinks li svg:hover {
  color: var(--primary400);
}

@media screen and (min-width: 991px) {
  .mobile_applynowbtn {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  footer .card_wrap ul.cards li {
    padding: 3px;
  }
  footer .card_wrap ul.cards li img {
    height: 20px;
  }
  footer ul.list.contacts li b {
    font-size: 17px;
  }
  footer ul.list.contacts li svg {
    font-size: 25px;
  }
  footer {
    padding: 50px 0;
  }
  footer h4 {
    margin: 30px 0 0;
  }
  header {
    background-color: #fff;
  }
  .topBar {
    background-color: var(--primary400);
    color: #fff;
  }
  .logo h2 {
    font-size: 22px;
    color: #fff;
  }
  .logo p {
    font-size: 14px;
    line-height: 1;
  }
  .topBar ul.contacts li a {
    color: #fff;
  }
  .topBar ul.contacts li svg {
    font-size: 18px;
  }
  .topBar ul.contacts li {
    font-size: 12px;
  }
  .topBar ul.contacts li b {
    font-size: 15px;
  }
  .bottomBar .applynow__btn {
    display: none;
  }
  .bottomBar {
    background-color: #fff;
    border-bottom: solid 1px var(--lightGray);
  }
  .mobile_applynowbtn > a {
    background-color: var(--secondary400);
    color: #fff !important;
    font-size: 14px;
    padding: 7px 10px;
  }
}

@media screen and (max-width: 767px) {
  .footerNew ul.Links_list{
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }
.overview{
  width: 100%;
  overflow: auto;
}
  footer .card_wrap ul.cards {
    justify-content: center;
    margin: 0 0 15px;
  }
  .topBar {
    padding: 8px 0;
  }
  .topBar ul.contacts li,
  .topBar ul.contacts li:last-child {
    font-size: 0;
    border: none;
    padding: 0;
  }
  .topBar ul.contacts li b {
    display: none;
  }
  .logo h2 {
    font-size: 20px;
  }
  .logo p {
    font-size: 10px;
  }
  .topBar ul.contacts li svg {
    font-size: 23px;
    margin-left: 10px;
  }
  .bottomBar a {
    color: var(--text400);
  }
}
