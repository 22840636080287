:root {
  --primary400: #0082dc;
  --secondary400: #2f4662;
  --text400: #002e62;
  --lightGray: #e1e1e1;
  --darkGray: #000;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: var(--lightGray);
}
::-webkit-scrollbar-thumb {
  background: var(--secondary400);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* body {
	font-family: "Ubuntu", sans-serif;
	color: var(--text400);
	line-height: 1.5;
	background-image: linear-gradient(to bottom, #2e65a8, #2f4662);
} */
a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--darkGray);
  margin: 20px 0 10px;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 30px;
}
h4 {
  font-size: 20px;
}
p a,
li a {
  color: var(--primary400);
}
.list-none {
  list-style: none;
}
section {
  padding: 80px 0;
}
.flex-1 {
  flex: 1;
}
label.form-label,
label.form-check-label {
  font-size: 14px;
  margin: 0;
}
header {
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 15;
  width: 100%;
  transform: translateY(0);
}
header.sticky-header {
  transform: translateY(-78.91px);
}

header nav.navbar .navbar-nav {
  justify-content: space-between;
  align-items: center;
}

header .navbar-toggler {
  border: none;
  padding: 0;
  margin-left: auto;
}
header .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
header .navbar .nav-item a.active {
  background-color: #fff;
  color: #000;
}
aside.homeSideBar,
aside.imagePanel {
  background-image: linear-gradient(
    to bottom,
    var(--text400),
    var(--secondary400)
  );
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}
aside.homeSideBar,
aside.customerPanel {
  background-image: linear-gradient(
    to bottom,
    var(--text400),
    var(--secondary400)
  );
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}
aside.homeSideBar h3,
aside.customerPanel h3 {
  width: 100%;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  background-color: var(--primary400);
  padding: 15px;
  margin: 0;
}
aside.homeSideBar ul,
aside.customerPanel ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
aside.homeSideBar ul li,
aside.customerPanel ul li {
  padding: 8px 15px;
  border-bottom: dashed 1px var(--primary400);
}
aside.homeSideBar ul li:last-child,
aside.customerPanel ul li:last-child {
  border: none;
}
aside.homeSideBar ul li a {
  width: 100%;
  color: #fff;
}
aside.customerPanel ul li a {
  color: #000;
}
aside.customerPanel {
  background-image: none;
  background-color: var(--lightGray);
}
.button_global {
  background-color: var(--secondary400);
  color: #fff;
  line-height: 1;
  padding: 10px 25px;
  border-radius: 3px;
  transition: 0.3s ease;
}
.button_global:hover {
  background-color: var(--darkGray);
}
main.main-top-margin {
  margin-top: 130px;
}

.banner_wrapper {
  text-align: center;
  background-image: url(./assets/img/banner.jpg);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 30px 0;
  background-color: #fff;
  border-bottom: solid 1px #ddd;
}
.banner_wrapper h1 {
  font-weight: 700;
  font-size: 38px;
  color: var(--darkGray);
}
.homebanner{
  background-image: url(./assets/img/homebanner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  border-radius: 10px;
  margin-bottom: 15px;
}
section.apply_instant {
  background-color: var(--lightGray);
  padding: 50px 0;
}
section.apply_instant h2 {
  margin: 0;
}
section.apply_instant .button_global {
  padding: 20px 45px;
  border-radius: 10px;
  font-size: 18px;
}
.online_apply_main_wrapper h5 {
  color: var(--primary400);
  font-weight: 600;
}
.summary_box {
  position: sticky;
  top: 70px;
}
.online_apply_main_wrapper .steps_bar {
  width: 50%;

  margin: 0 auto;
}
.online_apply_main_wrapper .steps_bar ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  gap: 5px;
}
.online_apply_main_wrapper .steps_bar ul li {
  flex: 1;

  text-align: center;
  position: relative;
}
.online_apply_main_wrapper .steps_bar ul li span {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 100%;
  text-align: center;
  background-color: var(--secondary400);
}
.online_apply_main_wrapper .steps_bar .progress {
  width: 75%;
  margin: 10px auto 0;
  height: 10px;
}
.online_apply_main_wrapper .steps_bar .progress-bar {
  background-color: var(--secondary400);
}
.step_btn {
  width: 100%;
  background-color: var(--primary400);
  color: #fff;
  border: solid 1px var(--primary400);
  padding: 10px;
}
.step_btn.prev {
  background-color: #fff;
  border: solid 1px var(--text400);
  color: var(--text400);
}
.step_btn:hover {
  background-color: var(--primary400);
}
.profile-upload-box {
  width: 130px;
  height: 190px;
  cursor: pointer;
}
.profile-upload-box-children-accompany {
  width: 90px;
  height: 100px;
  cursor: pointer;
  background-color: #fff;
  margin: 0 auto;
}
.profile-upload-box-children-accompany img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.errorText {
  font-size: 12px;
  color: red;
  margin: 0;
}
.file-upload-container{
  padding-top: 15px;
}
.file-upload-container input {
  display: none;
}
.upload-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-upload-box div {
  text-align: center;
}

.profile-upload-box p {
  margin: 0;
  font-size: 14px;
}
.profile-upload-box-children-accompany p {
  text-align: center;
  padding: 0 10px;
}
.form-select:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: none;
  outline: none;
  border: solid 1px var(--primary400);
}
.my_tool_tip > .tooltip-inner {
  max-width: 100%;
  /* text-align: initial; */
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
}
table {
  font-size: 13px;
}
table svg {
  font-size: 18px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.loader p {
  font-size: 18px;
  font-weight: bold;
}
.load {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: solid 8px;
  border-color: var(--primary400) transparent var(--text400) transparent;
  animation: loading 1s linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.accordion-item {
  border: none;
}
button.accordion-button {
  background-color: transparent;
  /* padding-left: 0; */
}
button.accordion-button:focus {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
}
.applynowformwrapper .accordion svg:hover {
  fill: #000;
}
.applynowformwrapper .accordion svg {
  cursor: pointer;
}
.applynowformwrapper h5{
  background-color: #ddd;
  padding: 6px 10px;
  margin: 20px 0 10px;
  font-size: 18px;
}
.applynowformwrapper .accordion h5{
  padding: 0;
}
.applynowformwrapper .accordion-button{
  padding: 10px;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.form-control {
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
}
.submit_btn {
  background-color: var(--secondary400);
  color: #fff;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 4px;
  transition: 0.3s ease;
  line-height: 1;
  border: solid 2px var(--secondary400);
  transition: 0.2s ease;
}
.submit_btn:hover {
  background-color: var(--secondary400);
  color: #fff;
  border: solid 2px var(--secondary400);
  transform: scale(0.95);
}

section.applynowformwrapper.details input:disabled {
  background-color: transparent;
  border: none;
  color: #000;
}

section.applynowformwrapper.details label.form-label {
  font-weight: 700;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__month-dropdown-container .react-datepicker__month-select {
  padding: 4px 0px;
  margin: 5px 0px;
}
.react-datepicker__year-dropdown-container .react-datepicker__year-select {
  padding: 4px 0px;
  margin: 5px 0px;
}
.form-control, .form-select {
  background-color: #e7eff7;
  border-color: rgba(1, 130, 220, 0.478);
  border-radius: 2px;
}
section.applynowformwrapper.preview_wrapper h5 {
  background-color: #0182dcba;
  color: #fff;
}
.preview_wrapper{
  text-transform: capitalize;
}

.labelbuttons{
  width: 100%;
  height: 100%;
  padding: 20px 15px;
  text-align: center;
  border-radius: 10px;
  background-image: url(./assets/img/pattern.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
}
.labelbuttons *{
  position: relative;
  z-index: 2;
}
.labelbuttons h4{
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 10px;
  color: #fff;
}
.labelbuttons p{
  margin: 0;
  line-height: 1;
}
.labelbuttons::after{
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .3;
  transition: .3s ease;
}
.labelbuttons:hover::after{
  opacity: .7;
}
.labelbuttons.first::after{
  background-color: #20afda;
  
}
.labelbuttons.second::after{
  background-color: #c72121;
}
.labelbuttons.third::after{
  background-color: #e1de27;
}

/* RESPONSIVE======================================================= */
@media screen and (max-width: 991px) {
  section.apply_instant {
    padding: 30px 0;
  }
  section.apply_instant h2 {
    font-size: 25px;
    margin: 0;
  }
  section.apply_instant .button_global {
    padding: 15px 25px;
    border-radius: 7px;
    font-size: 16px;
  }
  .banner_wrapper {
    background-size: cover;
    padding: 70px 0;
    background-position: left;
  }
  .banner_wrapper h1 {
    font-size: 30px;
  }
  main.main-top-margin {
    margin-top: 120px;
  }
  header nav.navbar .navbar-nav .nav-item {
    width: 100%;
    text-align: right;
  }
  header nav.navbar .navbar-nav a {
    width: 100%;
    padding: 3px 0;
    color: var(--text400);
  }
  header nav.navbar .navbar-nav a:focus {
    background-color: var(--lightGray);
  }
  header.sticky-header {
    transform: translateY(-73.39px);
  }
}

/* TABLE ================================================== */

@media screen and (max-width: 767px) {
  .online_apply_main_wrapper .steps_bar ul li {
    font-size: 11px;
  }
  .summary_box {
    margin-top: 30px;
  }
  .online_apply_main_wrapper .steps_bar {
    width: 100%;
  }
  .table_mobile {
    width: 100%;
    overflow-x: auto;
  }
  .table_mobile::-webkit-scrollbar {
    display: none;
  }
  section.apply_instant h2 {
    font-size: 19px;
    margin: 0 0 20px;
  }
  h2 {
    font-size: 23px;
  }
  h4 {
    font-size: 17px;
  }
  h3 {
    font-size: 19px;
  }
  .banner_wrapper {
    background-image: url(./assets/img/banner-mobile.jpg);
    padding: 50px 0;
    /* background-size: cover; */
    background-position: bottom;
  }
  .banner_wrapper h1 {
    font-size: 20px;
  }
  main.main-top-margin {
    margin-top: 100px;
  }
  header.sticky-header {
    transform: translateY(-53px);
  }
}
